.summary-content {
    display: flex;
    flex-direction: column;

    max-height: 76vh;
    overflow: hidden;
    padding: 0 2vw 2vw 2vw;

    margin: 2vh 2vw 2vh 2vw;
    border: 1px solid #000;

    cursor: pointer;
}

.summary-content h1 {
    margin: 0;
}

.summary-content img {
    max-width: 92vw;
    height: auto;
}

