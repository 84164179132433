@media screen and (max-width: 1024px) {

    aside {
        display: none;
    }

    .publications {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
    }

    .publications-loading {
        padding: 20px;
        text-align: center;
    }

    .markdown-content {
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        padding: 0 10px 0 10px;
    }

    .contact-us {
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        padding: 0 10px 0 10px;
        text-align: center;
    }

    .contact-us>form {
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        row-gap: 10px;
    }

    .circular-progress {
        padding: 20px;
        text-align: center;
    }

    .publication-content {
        padding: 0 2vw 0 2vw;
    }

    .summary-content .date,
    .publication-content .date {
        margin: 0;
        text-align: right;
        color: #666;
    }

    .informations {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4px;
    }

    @keyframes notif-animation {
        0% {
            transform: rotate(10deg);
        }

        50% {
            transform: rotateZ(-10deg);
        }

        100% {
            transform: rotate(10deg);
        }
    }

    .notif-icon>button {
        transform-origin: top center;
        transform: rotate(0deg);
    }

    .notif-icon>.animate {
        animation: notif-animation .5s infinite alternate;
    }

    .more-publications {
        text-align: center;
    }

    blockquote {
        padding-left: 10px;
        border-left: 2px solid #ccc
    }

    .faq-section {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
    }

    .faq-section ul {
        padding: 0;
    }

    .faq-section li {
        display: inline-block;
        background-color: #55befc;
        max-width: 80vw;
        padding: 4px;
        border-radius: 10px;
    }

    .faq-section ul::before {
        display: inline-block;
        background-image: url(https://www.svgrepo.com/show/239140/chat-conversation.svg);
        /* background-image: url(https://www.svgrepo.com/show/142829/communication.svg); */
        background-size: 24px;
        width: 24px;
        height: 24px;
        content: '';
    }

    .faq-section blockquote {
        display: flex;
        flex-direction: row;
        justify-content: end;
        border: none;
        margin: 0;
    }

    .faq-section blockquote::after {
        display: inline-block;
        background-image: url(https://www.svgrepo.com/show/239142/chat-conversation.svg);
        background-size: 24px;
        width: 24px;
        height: 24px;
        content: '';
    }

    .faq-section blockquote>p {
        background-color: rgb(176, 248, 176);
        margin: 0;
        padding: 4px;
        border-radius: 10px;
        max-width: 80vw;
        margin-right: 5px;
    }

    table {
        width: 100%;
    }

    th {
        width: 100vw;
        background-color: #b9b9b9;
    }

    tr:nth-of-type(odd) {
        background-color: #eceaea;
    }

    .documents-table td:nth-child(1) {
        width: 75%;
    }
}