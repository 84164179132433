/* ################################################# */
/*                                                   */
/*                 MOBILE SCREEN                     */
/*                                                   */
/* ################################################# */


@media screen and (max-width: 1024px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        background-color: #e8e6e6;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .footer .legals-links,
    .footer .copyright {
        margin-top: 10px;
        text-align: center;
    }

    .footer .legals-links {
        line-height: 30px;
    }

    .footer .legals-links a {
        text-decoration: none;
        color: #000;
    }

    .footer .newsletter {
        margin: 0 20px 0 20px;
    }

    .footer .desktop,
    .desktop-display {
        display: none;
    }
}

/* ################################################# */
/*                                                   */
/*                 DESKTOP SCREEN                    */
/*                                                   */
/* ################################################# */


@media screen and (min-width: 1024px) {
    .legals-links {
        display: none;
    }

    .footer {
        /* background-color: lightcyan; */

        display: grid;
        grid-template-columns: 40vw 58vw;
        grid-template-rows: auto;
        grid-template-areas:
            "newsletter desktop"
            "copyright copyright";

        background-color: #e8e6e6;
        padding: 20px;
    }

    .newsletter {
        grid-area: newsletter;
    }

    .newsletter .desktop-display {
        color: grey;
    }

    .desktop {
        /* background-color: lightcoral; */

        grid-area: desktop;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

    }

    .sitemap {
        grid-area: sitemap;
    }

    .sitemap ul {
        padding: 0;
    }

    .sitemap li {
        list-style-type: none;
    }

    .sitemap a {
        text-decoration: none;
        color: #000;
    }

    .contact {
        grid-area: contact;
    }

    .copyright {
        grid-area: copyright;
        text-align: center;
    }

}