/* ################################################# */
/*                                                   */
/*                 MOBILE SCREEN                     */
/*                                                   */
/* ################################################# */

@media screen and (max-width: 1024px) {
    html {
        background-color: #ffffff;
    }

    body {
        min-height: 100vh;
        margin: 0;

        display: flex;
        flex-direction: column;
    }

    main {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        flex: 1;
    }

    footer {
        width: 100%;
    }
}


/* ################################################# */
/*                                                   */
/*                 DESKTOP SCREEN                    */
/*                                                   */
/* ################################################# */


@media screen and (min-width: 1024px) {
    html {
        background-color: #fff;
    }

    body {
        min-height: 100vh;

        display: flex;
        flex-direction: column;
    }

    main {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
    }

    footer {
        width: 100vw;
    }

}