/* ################################################# */
/*                                                   */
/*                 MOBILE SCREEN                     */
/*                                                   */
/* ################################################# */

@media screen and (max-width: 1024px) {
    .header {
        /* background-color: lightgreen; */

        display: flex;
        flex-direction: column;
        justify-content: stretch;
    }

    .header {
        border-bottom: 1px solid #000;
    }

    .app-menu {
        /* background-color: lightsalmon; */

        display: flex;
        container: flex;
        flex-direction: row;
        align-items: center;
    }

    .app-menu {
        border-bottom: 1px solid #000;
        height: 50px;
        padding: 0 20px 0 20px;
    }

    .app-menu .socials-links {
        flex-grow: 1;
    }

    .app-menu .menu-items {
        /* background-color: lightyellow; */

        flex-shrink: 1;
        text-align: center;
    }

    .app-menu .menu-desktop {
        display: none;
    }

    .header .reason p {
        /* background-color: lightpink; */

        max-width: 70%;
        margin: auto;

        font-size: 15px;
        text-align: center;
        line-height: 20px;
        letter-spacing: .4rem;
    }

    .header .title a {
        text-decoration: none;
        color: inherit;
    }

    .header .title p {
        /* background-color: lightgoldenrodyellow; */

        max-width: 90%;
        margin: auto;

        font-size: 40px;
        text-align: center;
    }

    .drawer-menu {
        /* background-color: lightblue; */

        display: flex;
        flex-direction: column;
        justify-content: stretch;

        align-items: stretch;
        align-content: stretch;
    }

    .drawer-menu-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        /* align-items: stretch; */
    }

    .close-button {
        height: 15vh;
        /* background-color: lightcyan; */
        text-align: right;
        padding: 10px;
    }

    .menu-list {
        height: 85vh;
        /* background-color: lightpink; */

        display: flex;
        flex-direction: column;
    }

    .menu-list a {
        padding: 25px 0 25px;
        text-align: center;

        font-size: 1.2rem;
        text-decoration: none;
        color: #000;
    }

    .menu-list a:active {
        color: #6600ea;
    }
}

/* ################################################# */
/*                                                   */
/*                 DESKTOP SCREEN                    */
/*                                                   */
/* ################################################# */

@media screen and (min-width: 1024px) {
    .header {
        /* background-color: lightgreen; */

        display: flex;
        flex-direction: column;
        justify-content: stretch;
        max-width: 100vw;
    }

    /* .app-menu {
        background-color: lightsalmon;

        display: flex;
        flex-direction: row;
        align-items: center;
        
        order: 3;
        
        max-width: 95vw;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        margin: 0 auto;
        } */

    .app-menu {
        order: 3;
    }

    .menu-desktop {
        /* background-color: lightslategray; */

        display: flex;
        flex-direction: row;
        align-items: center;

        max-width: 100vw;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        margin: 0 auto;
    }

    .app-menu .socials-links {
        /* background-color: lightyellow; */

        position: absolute;
        top: 10px;
        left: 10px;
    }

    .app-menu .menu-items {
        display: none;
    }

    .header .reason p {
        /* background-color: lightpink; */

        max-width: 90vw;
        margin: 0 auto;

        font-size: 16px;
        text-align: center;
        letter-spacing: .5rem;
    }

    .header .title a {
        text-decoration: none;
        color: inherit;
    }

    .header .title p {
        /* background-color: lightgoldenrodyellow; */

        max-width: 60vw;
        margin: 0 auto;

        font-size: 100px;
        text-align: center;

        line-height: normal;
    }

    .menu-list {
        /* background-color: lightskyblue; */

        width: 100%;

        display: flex;
        flex-direction: row;

        justify-content: center;
        align-items: stretch;
        align-content: center;
    }

    .menu-list .link-tile {
        /* background-color: lightcoral; */

        display: inline-block;
        min-width: 80px;
        height: 30px;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        border-left: 1px solid #000;
    }

    .menu-list .link-tile:last-child {
        border-right: 1px solid #000;
    }

    .menu-list a {
        text-decoration: none;
        color: #000;
    }

    .menu-list a:active {
        color: #6600ea;
    }
}